import * as proto from 'src/proto/compiled-protos';
import { Component, Input, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ActionObserver } from 'src/app/general/components/action/action';
import { ActionComponent } from 'src/app/general/components/action/action.component';
import { LoadableContentComponent } from 'src/app/general/components/loadable-content/loadable-content.component';
import { LoadingController } from 'src/app/general/components/loadable-content/loading-controler';
import { LocalizationService } from 'src/app/general/services/localization.service';
import { LoggingService } from 'src/app/general/services/logging.service';
import { LoadingMessage, ErrorResult, SuccessResult } from 'src/app/general/util/result';
import { Util } from 'src/app/general/util/util';
import { BackendService } from '../../services/backend.service';
import { NavigationService } from '../../services/navigation.service';
import { OrdersService } from '../../services/orders.service';
import { Formatter } from '../../util/formatter';
import { PageComponent } from '../page/page.component';
import { ComponentUtil } from 'src/app/general/util/component-util';
import { DialogService } from 'src/app/general/services/dialog.service';
import { SessionService } from '../../services/session.service';
import { EnvironmentUtil } from '../../util/environment-util';

@Component({
  selector: 'app-order',
  standalone: true,
  imports: [
    CommonModule,
    PageComponent,
    ActionComponent,
    TranslateModule,
    LoadableContentComponent,
  ],
  templateUrl: './order.component.html',
  styleUrls: ['./order.component.css']
})
export class OrderComponent {
  @Input() orderId?: string;
  @Input() orderInput?: proto.waiternow.common.IOrderProto;
  // This is set by the navigation service: home, order_history
  @Input() fromPage?: string;

  public order: proto.waiternow.common.IOrderProto;
  public isOrderMarkedAsCompleted: boolean;
  public isOrderRefunded: boolean;

  public orderLoadingController: LoadingController;

  constructor(
      private ordersService: OrdersService,
      private backendService: BackendService,
      private loggingService: LoggingService,
      private localizationService: LocalizationService,
      private translateService: TranslateService,
      private dialogService: DialogService,
      public navigationService: NavigationService,
      public sessionService: SessionService) {
    this.order = new proto.waiternow.common.OrderProto();
    this.isOrderMarkedAsCompleted = false;
    this.isOrderRefunded = false;
    this.orderLoadingController = new LoadingController();
  }

  ngOnInit(): void {
    if (this.orderInput) {
      this.order = this.orderInput;
      this.checkOrderStatus();
    } else {
      this.loadOrder();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (ComponentUtil.bindingChanged('orderId', changes)) {
      this.ngOnInit();
    }
    if (ComponentUtil.bindingChanged('orderInput', changes)) {
      this.ngOnInit();
    }
  }

  public loadOrder(): void {
    if (!this.orderId) {
      return;
    }
    this.orderLoadingController.onLoadingStarted(LoadingMessage.withMessageTranslateId('loading_order'));
    this.backendService.getOrder(
      /* orderId= */ Util.safeString(this.orderId),
      /* onSuccess= */ order => {
        this.orderLoadingController.onSuccess();
        if (order) {
          this.order = order;
          this.checkOrderStatus();
        }
      },
      /* onError= */ error => {
        this.orderLoadingController.onError(ErrorResult.withErrorAndMessageTranslateId(error, 'error_loading_order'));
      }
    );
  }

  private checkOrderStatus(): void {
    if (this.order) {
      if (this.order.status && this.order.status.completed) {
        this.isOrderMarkedAsCompleted = true;
      }
      if (this.isRefundedRefunded()) {
        this.isOrderMarkedAsCompleted = true;
        this.isOrderRefunded = true;
      }
      if (!this.order.status || !this.order.status.acked) {
        this.ackOrder();
      }
    }
  }

  private ackOrder() {
    if (!this.order) {
      return;
    }
    this.backendService.ackOrder(
      Util.safeString(this.order.id),
      /* onSuccess= */ () => {
        if (this.order) {
          this.ordersService.orderAcked(this.order);
        }
      },
      /* onError= */ error => {
        this.loggingService.logErrorWithCause(error, 'Error marking order as acked');
      }
    );
  }

  public markOrderAsCompleted(actionObserver: ActionObserver): void {
    if (!this.order) {
      actionObserver.onError(ErrorResult.empty())
      return;
    }
    this.backendService.markOrderAsCompleted(
      Util.safeString(this.order.id),
      /* onSuccess= */ () => {
        this.ordersService.orderCompleted(this.order);
        this.isOrderMarkedAsCompleted = true;
        actionObserver.onSuccess(SuccessResult.empty());
      },
      /* onError= */ error => {
        actionObserver.onError(ErrorResult.withErrorAndMessageTranslateId(error, 'error_internal'));
      }
    );
  }

  public refundOrder(actionObserver: ActionObserver) {
    if (!this.order) {
      actionObserver.onError(ErrorResult.empty())
      return;
    }
    this.translateService.get('confirmation_refund_order').subscribe(text => {
      this.dialogService.openConfirmationDialog(
        text,
        /* onYes= */ () => {
          this.backendService.refunOrder(
            Util.safeString(this.order.id),
            /* onSuccess= */ () => {
              this.ordersService.orderRefunded(this.order);
              this.isOrderMarkedAsCompleted = true;
              this.isOrderRefunded = true;
              actionObserver.onSuccess(SuccessResult.empty());
              // We do a best effort mark as complete so the order doesn't keep appearing
              if (this.isCompletedStatusExpected()) {
                this.markOrderAsCompleted({ onSuccess: successResult => {}, onError: error => {}});
              }
            },
            /* onError= */ error => {
              actionObserver.onError(ErrorResult.withErrorAndMessageTranslateId(error, 'error_internal'));
            }
          );
        },
        /* onNo= */ () => {
          actionObserver.onSuccess(SuccessResult.empty());
        }
      );
    });
  }

  public goBack(): void {
    if (this.fromPage == 'order_history') {
      this.navigationService.goToOrderHistoryPage();
    } else {
      this.navigationService.goToHomePage();
    }
  }

  public openReceipt(): void {
    this.navigationService.openInNewTab(EnvironmentUtil.resolveBackendUrl('service/user/order/receipt/' + this.order.id));
  }

  public closeWindow(): void {
    // This code does not worek on Chrome

    // Basically window.close() only work if any window launched by window.open()
    // Here we firstly redirect to same url then close
    let newWindow = open(location.href, '_self');
    if (newWindow) {
      newWindow.close();
    }
  }

  public isWaiterRequest(): boolean {
    if (this.order && this.order.waiterRequest) {
      return true;
    }
    return false;
  }

  public isStructuredMenuOrder(): boolean {
    if (this.order && this.order.structuredMenuOrder) {
      return true;
    }
    return false;
  }

  public isCompletedStatusExpected(): boolean {
    if (this.order && this.order.redundantData && this.order.redundantData.isCompletedStatusExpected) {
      return true;
    }
    return false;
  }

  public isPaidOrder(): boolean {
    if (this.order && this.order.status && this.order.status.payment) {
      return true;
    }
    return false;
  }

  public isRefundedRefunded(): boolean {
    if (this.order.status && this.order.status.payment) {
      const payment = this.order.status.payment;
      if (payment.status == proto.waiternow.common.PaymentProto.Status.REFUNDED) {
        return true;
      }
      if (payment.partialRefunds && payment.partialRefunds.length > 0) {
        return true;
      }
    }
    return false;
  }

  public getRefundTimes(): Array<string> {
    const refundTimes: Array<string> = [];
    if (this.order.status && this.order.status.payment) {
      const payment = this.order.status.payment;
      if (payment.status == proto.waiternow.common.PaymentProto.Status.REFUNDED) {
        refundTimes.push(Formatter.formatTimestampProtoWithLocale(payment.refundStatus?.refundTime));
      }
      else {
        if (payment.partialRefunds) {
          for (const partialRefund of payment.partialRefunds) {
            refundTimes.push(Formatter.formatTimestampProtoWithLocale(partialRefund.refundTime) + ' ' + Formatter.formatMoney(partialRefund.amount));
          }
        }
      }
    }
    return refundTimes;
  }

  public getOrderCreationTime(): string {
    if (this.order) {
      return Formatter.formatTimestampProtoWithLocale(this.order.creationTime);
    }
    return '';
  }

  public getOrderNumber(): string {
    if (this.order && this.order.orderNumber) {
      return this.order.orderNumber.toString();
    }
    return '';
  }

  public getCustomerName(): string {
    if (this.order && this.order.redundantData) {
      return Util.safeString(this.order.redundantData.consumerName);
    }
    return '';
  }

  public getCustomerPhoneNumber(): string {
    if (this.order && this.order.status && this.order.status.payment) {
      return Formatter.formatPhoneNumber(this.order.status.payment.payerPhoneNumber);
    }
    return '';
  }

  public getPointOfServiceFriendlyName() {
    if (this.order && this.order.redundantData && this.order.redundantData.pointOfServiceFriendlyName) {
        return this.order.redundantData.pointOfServiceFriendlyName;
    }
    return '';
  }

  // NOTE: this method is duplicated in HomePageComponent
  public getOrderType(): string {
    if (this.order && this.order.redundantData) {
      const redundantData = this.order.redundantData;
      if (redundantData.isDelivery) {
        return  this.localizationService.isSpanish() ? 'Entrega a Domicilio' : 'Delivery';
      }
      else if (redundantData.isOnlinePickup) {
        return  this.localizationService.isSpanish() ? 'Para Recoger' : 'Pickup';
      }
      else if (redundantData.isOnsitePickup) {
        return  this.localizationService.isSpanish() ? 'Para Recoger' : 'Pickup';
      }
      else if (redundantData.pointOfServiceFriendlyName) {
        return redundantData.pointOfServiceFriendlyName;
      }
    }
    return '';
  }

  public getMenuEntries(): Array<proto.waiternow.common.StructuredMenuOrderProto.IMenuEntryProto> {
    if (this.order && this.order.structuredMenuOrder && this.order.structuredMenuOrder.menuEntries) {
      return this.order.structuredMenuOrder.menuEntries
    }
    return [];
  }

  public getMenuItems(combo: proto.waiternow.common.StructuredMenuOrderProto.IComboProto):
      Array<proto.waiternow.common.StructuredMenuOrderProto.IMenuItemProto> {
    let allMenuItems: Array<proto.waiternow.common.StructuredMenuOrderProto.IMenuItemProto> = [];
    if (combo.menuItems) {
      allMenuItems = allMenuItems.concat(combo.menuItems);
    }
    if (combo.menuItemSelections) {
      for (const menuItemSelection of combo.menuItemSelections) {
        if (menuItemSelection.selectedMenuItems) {
          allMenuItems = allMenuItems.concat(menuItemSelection.selectedMenuItems);
        }
      }
    }
    return allMenuItems;
  }

  public getQuantity(menuEntry: proto.waiternow.common.StructuredMenuOrderProto.IMenuEntryProto): number {
    if (menuEntry.combo) {
      return Util.safeNumber(menuEntry.combo.quantity);
    } else if(menuEntry.menuItem) {
      return Util.safeNumber(menuEntry.menuItem.quantity);
    }
    return 0;
  }

  public getText(textProto: proto.waiternow.common.ITextProto | null | undefined): string {
    let str: string | null | undefined;
    if (this.localizationService.isSpanish()) {
      str = Formatter.getText(textProto, proto.waiternow.common.Language.SPANISH);
    }
    else {
      str = Formatter.getText(textProto, proto.waiternow.common.Language.ENGLISH);
    }
    if (!str) {
      str = textProto?.text;
    }
    return Util.safeString(str);
  }

  public getWaiterRequestAction(): string {
    if (this.order && this.order.waiterRequest) {
      const waiterRequest = this.order.waiterRequest;
      if (waiterRequest.predefinedAction == proto.waiternow.common.OrderProto.WaiterRequestProto.PredefinedAction.CALL_WAITER) {
        return this.localizationService.isSpanish() ? 'Solicita mesero' : 'Calling for waiter';
      } else  if(waiterRequest.customMessage) {
        return this.localizationService.isSpanish() ? 'Mensaje' : 'Message';
      }
    }
    return '';
  }

  public getWaiterRequestCustomMessage(): string {
    if (this.order && this.order.waiterRequest) {
      const waiterRequest = this.order.waiterRequest;
      if(waiterRequest.customMessage) {
        return waiterRequest.customMessage;
      }
    }
    return '';
  }

  public getOrderTimeLabel(): string {
    if (!this.order || !this.order.orderTime) {
      return '';
    }

    if (this.order.redundantData) {
      const redundantData = this.order.redundantData;
      if (redundantData.isDelivery) {
        return this.localizationService.isSpanish() ? 'Hora de Entrega a Domicilio' : 'Requested Delivery Time';
      }
      else if (redundantData.isOnlinePickup) {
        return this.localizationService.isSpanish() ? 'Hora Para Recoger' : 'Pickup Time';
      }
      else if (redundantData.isOnsitePickup) {
        return this.localizationService.isSpanish() ? 'Hora Para Recoger' : 'Pickup Time';
      }
      else {
        return this.localizationService.isSpanish() ? 'Hora Para Orden' : 'Order Time';
      }
    }
    return '';
  }

  public getOrderTime(): string {
    if (!this.order || !this.order.orderTime) {
      return '';
    }
    return Formatter.formatDateTimeProtoWithLocale(this.order.orderTime);
  }

  // Estimated pickup time is to show to the business, estimated drop-off time is to show to the
  // customer in the order receipt.
  public getEstimatedPickupTime(): string {
    if (this.order && this.order.delivery && this.order.delivery.estimatedPickupTime) {
      return Formatter.formatTimestampProtoWithLocale(this.order.delivery.estimatedPickupTime);
    }
    return '';
  }

  public isDelivery() {
    if (this.order && this.order.redundantData && this.order.redundantData.isDelivery) {
      return true;
    }
    return false;
  }

  public getDeliveryAddress() {
    if (this.order && this.order.delivery && this.order.delivery.deliveryAddress) {
      return Formatter.formatAddress(this.order.delivery.deliveryAddress);
    }
    return '';
  }

  public getToGoLabel(): string {
    if (this.order.delivery && this.order.delivery.deliveryCarrier == proto.waiternow.common.DeliveryCarrier.SELF) {
      return  this.localizationService.isSpanish() ? 'Entrega a Domicilio' : 'Delivery';
    } else if(this.order.delivery && this.order.delivery.deliveryCarrier == proto.waiternow.common.DeliveryCarrier.DOORDASH) {
      return 'DoorDash'
    } else if(this.order.redundantData && this.order.redundantData.isOnlinePickup) {
      return  this.localizationService.isSpanish() ? 'Para Recoger' : 'Pickup';
    } else if(this.order.structuredMenuOrder && this.order.structuredMenuOrder.toGo) {
      return  this.localizationService.isSpanish() ? 'Para Llevar' : 'To Go';
    }
    return '';
  }

  public getNoCutleryLabel(): string {
    if (this.order && this.order.structuredMenuOrder && !this.order.structuredMenuOrder.includeCutlery) {
      if (this.order.redundantData) {
        const redundantData = this.order.redundantData;
        if (redundantData.isDelivery || redundantData.isOnlinePickup || this.order.structuredMenuOrder.toGo) {
          return this.localizationService.isSpanish() ? 'No Utencilios' : 'No Cutlery';
        }
      }
    }
    return '';
  }

  public getForLaterLabel(): string {
    if (this.order && this.order.orderTime) {
      return this.localizationService.isSpanish() ? 'Para Después' : 'For Later';
    }
    return '';
  }

  public getIdRequiredLabel(): string {
    if (this.containsAlcohol()) {
      return this.localizationService.isSpanish() ? 'ID Requerido' : 'ID Required';
    }
    return '';
  }

  private containsAlcohol(): boolean {
    if (this.order && this.order.structuredMenuOrder && this.order.structuredMenuOrder.menuEntries) {
      for (const menuEntry of this.order.structuredMenuOrder.menuEntries) {
        if (menuEntry.menuItem) {
          return Boolean(menuEntry.menuItem.containsAlcohol);
        } else if (menuEntry.combo) {
          const combo = menuEntry.combo;
          if (combo.menuItems) {
            for (const menuItem of combo.menuItems) {
              if (menuItem.containsAlcohol) {
                return true;
              }
            }
          }
          if (combo.menuItemSelections) {
            for (const menuItemSelection of combo.menuItemSelections) {
              if (menuItemSelection.selectedMenuItems) {
                for (const menuItem of menuItemSelection.selectedMenuItems) {
                  if (menuItem.containsAlcohol) {
                    return true;
                  }
                }
              }
            }
          }
        }
      }
    }
    return false;
  }

  public getContactlessDropoffLabel(): string {
    if (this.order.delivery && this.order.delivery.contactlessDropoff
        && this.order.delivery.deliveryCarrier == proto.waiternow.common.DeliveryCarrier.SELF) {
      return this.localizationService.isSpanish() ? 'Entrega sin Contacto' : 'Contactless';
    }
    return '';
  }

  public getDropoffInstructions(): string {
    if (this.order.delivery && this.order.delivery.dropoffInstructions
        && this.order.delivery.deliveryCarrier == proto.waiternow.common.DeliveryCarrier.SELF) {
      return this.order.delivery.dropoffInstructions;
    }
    return '';
  }
}
