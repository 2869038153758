import * as proto from 'src/proto/compiled-protos';
import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { PageComponent } from '../../components/page/page.component';
import { DataTableColumn, StringColumn } from 'src/app/general/components/data-table/data-table-column';
import { LoadingController } from 'src/app/general/components/loadable-content/loading-controler';
import { LoadingMessage, ErrorResult } from 'src/app/general/util/result';
import { BackendService } from '../../services/backend.service';
import { SessionService } from '../../services/session.service';
import { LoadableContentComponent } from 'src/app/general/components/loadable-content/loadable-content.component';
import { MatCardModule } from '@angular/material/card';
import { DataTableComponent } from 'src/app/general/components/data-table/data-table.component';
import { NavigationService } from '../../services/navigation.service';
import { StorageService } from '../../services/storage.service';
import { Util } from 'src/app/general/util/util';
import { DialogService } from 'src/app/general/services/dialog.service';
import { DeviceSessionService } from '../../services/device-session.service';

@Component({
  selector: 'app-select-location-page',
  standalone: true,
  imports: [
    CommonModule,
    PageComponent,
    TranslateModule,
    LoadableContentComponent,
    MatCardModule,
    DataTableComponent,
  ],
  templateUrl: './select-location-page.component.html',
  styleUrls: ['./select-location-page.component.css']
})
export class SelectLocationPageComponent {
  userLocationsLoadingController: LoadingController;

  locations: Array<proto.waiternow.common.ILocationProto>;
  columns: Array<DataTableColumn<proto.waiternow.common.ILocationProto>>;

  constructor(
      private backendService: BackendService,
      private sessionService: SessionService,
      private navigationService: NavigationService,
      private storageService: StorageService,
      private deviceSessionService: DeviceSessionService,
      private dialogService: DialogService,
      private translateService: TranslateService) {
    this.userLocationsLoadingController = new LoadingController();

    this.locations = new Array();
    this.columns = [
      new StringColumn(
        /* name= */ 'businessName',
        /* translateId= */ 'business_name',
        /* valueExtractor= */  location => location.redundantData?.businessName),
      new StringColumn(
        /* name= */ 'locationLame',
        /* translateId= */ 'location_name',
        /* valueExtractor= */  location => location.name,
        /* hideInCompactMode= */ false)
    ];
  }

  ngOnInit(): void {
    this.loadLocations();
  }

  public loadLocations(): void {
    if (this.sessionService.getLocations().length > 0) {
      this.onLocationsLoaded(this.sessionService.getLocations());
      return;
    }
    this.userLocationsLoadingController.onLoadingStarted(LoadingMessage.withMessageTranslateId('loading_locations'));
    this.backendService.findLocationsByUser(
      /* userId= */ '',
      /* onSuccess= */ userLocations => {
        this.userLocationsLoadingController.onSuccess();
        if (userLocations && userLocations?.locations) {
          this.onLocationsLoaded(Util.convertArray(userLocations?.locations, managedLocation => managedLocation.location));
        } else {
          this.onLocationsLoaded([]);
        }
      },
      /* onError= */ error => {
        this.userLocationsLoadingController.onError(ErrorResult.withErrorAndMessageTranslateId(error, 'error_loading_locations'));
      }
    );
  }

  private onLocationsLoaded(locations: Array<proto.waiternow.common.ILocationProto>) {
    if (locations) {
      this.locations = locations;
      this.sessionService.setLocations(this.locations);
      if (this.locations.length == 1) {
        this.onLocationSelected(this.locations[0]);
        return;
      }
      const storedLocationId = this.storageService.getLocationIdStore().get();
      if (storedLocationId && !this.sessionService.getLocation()) {
        for (const location of this.locations) {
          if (location.id == storedLocationId) {
            this.onLocationSelected(location);
          }
        }
      }
    } else {
      this.locations = [];
      this.sessionService.setLocations(this.locations);
    }
  }

  private onLocationSelected(location: proto.waiternow.common.ILocationProto | null | undefined) {
    if (location) {
      const currentLocation = this.sessionService.getLocation();
      if (!currentLocation || currentLocation.id != location.id) {
        this.sessionService.setLocation(location);
        this.deviceSessionService.startSession();
      }
      this.navigationService.goToHomePage();
    }
  }

  public onRowClick(location: proto.waiternow.common.ILocationProto) {
    if (location) {
      if(this.storageService.getLocationIdStore().get()
           && this.storageService.getLocationIdStore().get() != location.id) {
        this.translateService.get('confirmation_select_different_location', { locationName: location.redundantData?.businessName + ' ' + location.name }).subscribe(text => {
          this.dialogService.openConfirmationDialog(
            text,
            /* onYes= */ () => {
              this.onLocationSelected(location);
            },
            /* onNo= */ () => {

            }
          );
        });
      } else {
        this.onLocationSelected(location);
      }
    }
  }
}
