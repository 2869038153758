import * as proto from 'src/proto/compiled-protos';
import { ChangeDetectorRef, Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PageComponent } from '../../components/page/page.component';
import { TitleComponent } from 'src/app/general/components/title/title.component';
import { ActionComponent } from 'src/app/general/components/action/action.component';
import { SubtitleComponent } from 'src/app/general/components/subtitle/subtitle.component';
import { TranslateModule } from '@ngx-translate/core';
import { ActionObserver } from 'src/app/general/components/action/action';
import { SuccessResult, ErrorResult } from 'src/app/general/util/result';
import { Util } from 'src/app/general/util/util';
import { BackendService } from '../../services/backend.service';
import { SessionService } from '../../services/session.service';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FieldContainerDirective } from 'src/app/general/directives/field/field-container.directive';
import { FieldDirective } from 'src/app/general/directives/field/field.directive';
import { FormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import { DateAdapter, MAT_DATE_FORMATS, MAT_NATIVE_DATE_FORMATS, NativeDateAdapter } from '@angular/material/core';
import { NavigationService } from '../../services/navigation.service';
import { DataTableColumn, StringColumn, CheckboxColumn } from 'src/app/general/components/data-table/data-table-column';
import { Formatter } from '../../util/formatter';
import { ProtoUtil } from '../../util/proto-util';
import { DataTableComponent } from 'src/app/general/components/data-table/data-table.component';
import { LocalizationService } from 'src/app/general/services/localization.service';
import { RightAlignedCompactContentComponent } from 'src/app/general/components/right-aligned-compact-content/right-aligned-compact-content.component';
import { DialogService } from 'src/app/general/services/dialog.service';

export interface SalesSummaryReportField {
  name: string,
  value: string
}

@Component({
  selector: 'app-sales-summary-page',
  standalone: true,
  imports: [
    CommonModule,
    PageComponent,
    TranslateModule,
    TitleComponent,
    SubtitleComponent,
    ActionComponent,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatDatepickerModule,
    FieldContainerDirective,
    FieldDirective,
    DataTableComponent,
    RightAlignedCompactContentComponent
  ],
  providers: [ {provide: DateAdapter, useClass: NativeDateAdapter}, {provide: MAT_DATE_FORMATS, useValue: MAT_NATIVE_DATE_FORMATS} ],
  templateUrl: './sales-summary-page.component.html',
  styleUrls: ['./sales-summary-page.component.css']
})
export class SalesSummaryPageComponent {

  public salesSummaryReportFields: Array<SalesSummaryReportField>;
  public columns: Array<DataTableColumn<SalesSummaryReportField>>;

  public startDateFormControl: FormControl;
  public endDateFormControl: FormControl;

  public help: string = '';

  private textsInEnglish = new Map<string, string>();
  private textsInSpanish = new Map<string, string>();

  constructor(
      private sessionService: SessionService,
      private backendService: BackendService,
      private navigationService: NavigationService,
      private localizationService: LocalizationService,
      private dialogService: DialogService,
      private changeDetectorRef: ChangeDetectorRef) {
    // Columns do not have translateId becase we want the column name to be empty.
    // In this case the first colun has the name for each report field.
    this.columns = [
      new StringColumn(
        /* name= */ 'name',
        /* translateId= */ '',
        /* valueExtractor= */  field => field.name),
      new StringColumn(
        /* name= */ 'value',
        /* translateId= */ '',
        /* stateExtractor= */ field => field.value)
    ];
    this.salesSummaryReportFields = new Array();

    this.startDateFormControl = new FormControl(new Date(), [Validators.required]);
    this.endDateFormControl = new FormControl(new Date(), [Validators.required]);

    this.textsInEnglish.set('period', 'Period');
    this.textsInEnglish.set('orders', 'Orders');
    this.textsInEnglish.set('partially_refunded_orders', 'Partially Refunded Orders');
    this.textsInEnglish.set('refunded_orders', 'Refunded Orders');
    this.textsInEnglish.set('subtotal', 'Subtotal');
    this.textsInEnglish.set('discounts', 'Discounts');
    this.textsInEnglish.set('tax', 'Tax');
    this.textsInEnglish.set('tips', 'Tips');
    this.textsInEnglish.set('self_delivery_fees', 'Self Delivery Fees');
    this.textsInEnglish.set('self_delivery_tips', 'Self Delivery Tips');
    this.textsInEnglish.set('doordash_delivery_fees', 'DoorDash Delivery Fees');
    this.textsInEnglish.set('doordash_delivery_tips', 'DoorDash Delivery Tips');
    this.textsInEnglish.set('credit_card_fee_adjustment', 'Credit Card Fee Adjustment');
    this.textsInEnglish.set('stripe_fee', 'Stripe Fee');
    this.textsInEnglish.set('waiternow_fee', 'WaiterNow Fee');
    this.textsInEnglish.set('total', 'Total');
    this.textsInEnglish.set('refunds', 'Refunds');
    this.textsInEnglish.set('net_paid_to_business', 'Net Paid to Business');

    this.textsInSpanish.set('period', 'Periodo');
    this.textsInSpanish.set('orders', 'Órdenes');
    this.textsInSpanish.set('partially_refunded_orders', 'Órdenes Parcialmente Reembolsadas');
    this.textsInSpanish.set('refunded_orders', 'Órdenes Reembolsadas');
    this.textsInSpanish.set('subtotal', 'Subtotal');
    this.textsInSpanish.set('discounts', 'Descuentos');
    this.textsInSpanish.set('tax', 'Impuestos');
    this.textsInSpanish.set('tips', 'Propinas');
    this.textsInSpanish.set('self_delivery_fees', 'Cuotas de Entregas Propias');
    this.textsInSpanish.set('self_delivery_tips', 'Propinas de Entregas Propias');
    this.textsInSpanish.set('doordash_delivery_fees', 'Cuotas de Entregas de DoorDash');
    this.textsInSpanish.set('doordash_delivery_tips', 'Propinas de Entregas de DoorDash');
    this.textsInSpanish.set('credit_card_fee_adjustment', 'Ajuste de Cuota de Tarjeta de Crédito');
    this.textsInSpanish.set('stripe_fee', 'Cuota de Stripe');
    this.textsInSpanish.set('waiternow_fee', 'Cuota de WaiterNow');
    this.textsInSpanish.set('total', 'Total');
    this.textsInSpanish.set('refunds', 'Reembolsos');
    this.textsInSpanish.set('net_paid_to_business', 'Neto Pagado al Negocio');
  }

  ngOnInit(): void {
    // TODO: if the sales summary report page is refreshed, it would show empty data because locations
    // have not been fetched. The home page redirects to SelectLocationPage if no location has
    // been selected, and then SelectLocationPage redirects back to the home page once the location
    // is selected.
    if (!this.sessionService.getLocation()) {
      this.navigationService.goToHomePage();
      return;
    }
  }

  public getSalesSummaryReport(actionObserver: ActionObserver): void {
    const period = new proto.waiternow.common.DateRangeProto();
    period.from = ProtoUtil.dateToDateProto(this.startDateFormControl.value);
    period.to = ProtoUtil.dateToDateProto(this.endDateFormControl.value);

    this.backendService.getSalesSummaryReport(
      Util.safeString(this.sessionService.getLocation()?.id),
      period,
      /* onSuccess= */ salesSummaryReport => {
        this.formatSalesSummaryReport(salesSummaryReport);
        actionObserver.onSuccess(SuccessResult.empty());
        this.changeDetectorRef.detectChanges();
      },
      /* onError= */ error => {
        actionObserver.onError(ErrorResult.withErrorAndMessageTranslateId(error, 'error_internal'));
      }
    );
  }

  public getLocationName(): string {
    const location = this.sessionService.getLocation();
    if (location) {
      return location.redundantData?.businessName + ' ' + location.name;
    }
    return '';
  }

  private formatSalesSummaryReport(salesSummaryReport: proto.waiternow.common.ISalesSummaryReportProto | null | undefined): void {
    if (!salesSummaryReport) {
      return;
    }

    this.salesSummaryReportFields = [];
    this.salesSummaryReportFields.push({ name: this.getText('period'), value: Formatter.formatDateProto(salesSummaryReport.period?.from) + ' - ' + Formatter.formatDateProto(salesSummaryReport.period?.to) });
    this.salesSummaryReportFields.push({ name: this.getText('orders'), value: Util.safeNumber(salesSummaryReport.orders).toString() });
    if (Util.safeNumber(salesSummaryReport.partiallyRefundedOrders) > 0) {
      this.salesSummaryReportFields.push({ name: this.getText('partially_refunded_orders'), value: Util.safeNumber(salesSummaryReport.partiallyRefundedOrders).toString() });
    }
    if (Util.safeNumber(salesSummaryReport.refundedOrders) > 0) {
      this.salesSummaryReportFields.push({ name: this.getText('refunded_orders'), value: Util.safeNumber(salesSummaryReport.refundedOrders).toString() });
    }
    if (!ProtoUtil.isMoneyZero(salesSummaryReport.subtotal)) {
      this.salesSummaryReportFields.push({ name: this.getText('subtotal'), value: Formatter.formatMoney(salesSummaryReport.subtotal) });
    }
    if (!ProtoUtil.isMoneyZero(salesSummaryReport.discounts)) {
      this.salesSummaryReportFields.push({ name: this.getText('discounts'), value: Formatter.formatMoney(salesSummaryReport.discounts) });
    }
    if (!ProtoUtil.isMoneyZero(salesSummaryReport.tax)) {
      this.salesSummaryReportFields.push({ name: this.getText('tax'), value: Formatter.formatMoney(salesSummaryReport.tax) });
    }
    if (!ProtoUtil.isMoneyZero(salesSummaryReport.tips)) {
      this.salesSummaryReportFields.push({ name: this.getText('tips'), value: Formatter.formatMoney(salesSummaryReport.tips) });
    }
    if (!ProtoUtil.isMoneyZero(salesSummaryReport.deliveryFeesSelf)) {
      this.salesSummaryReportFields.push({ name: this.getText('self_delivery_fees'), value: Formatter.formatMoney(salesSummaryReport.deliveryFeesSelf) });
    }
    if (!ProtoUtil.isMoneyZero(salesSummaryReport.deliveryTipsSelf)) {
      this.salesSummaryReportFields.push({ name: this.getText('self_delivery_tips'), value: Formatter.formatMoney(salesSummaryReport.deliveryTipsSelf) });
    }
    if (!ProtoUtil.isMoneyZero(salesSummaryReport.deliveryFeesDoorDash)) {
      this.salesSummaryReportFields.push({ name: this.getText('doordash_delivery_fees'), value: Formatter.formatMoney(salesSummaryReport.deliveryFeesDoorDash) });
    }
    if (!ProtoUtil.isMoneyZero(salesSummaryReport.deliveryTipsDoorDash)) {
      this.salesSummaryReportFields.push({ name: this.getText('doordash_delivery_tips'), value: Formatter.formatMoney(salesSummaryReport.deliveryTipsDoorDash) });
    }
    if (!ProtoUtil.isMoneyZero(salesSummaryReport.creditCardFeeAdjustment)) {
      this.salesSummaryReportFields.push({ name: this.getText('credit_card_fee_adjustment'), value: Formatter.formatMoney(salesSummaryReport.creditCardFeeAdjustment) });
    }
    if (!ProtoUtil.isMoneyZero(salesSummaryReport.stripeFee)) {
      this.salesSummaryReportFields.push({ name: this.getText('stripe_fee'), value: Formatter.formatMoney(salesSummaryReport.stripeFee) });
    }
    if (!ProtoUtil.isMoneyZero(salesSummaryReport.waiterNowFee)) {
      this.salesSummaryReportFields.push({ name: this.getText('waiternow_fee'), value: Formatter.formatMoney(salesSummaryReport.waiterNowFee) });
    }
    if (!ProtoUtil.isMoneyZero(salesSummaryReport.total)) {
      this.salesSummaryReportFields.push({ name: this.getText('total'), value: Formatter.formatMoney(salesSummaryReport.total) });
    }
    if (!ProtoUtil.isMoneyZero(salesSummaryReport.refunds)) {
      this.salesSummaryReportFields.push({ name: this.getText('refunds'), value: Formatter.formatMoney(salesSummaryReport.refunds) });
    }
    if (!ProtoUtil.isMoneyZero(salesSummaryReport.netPaidToBusiness)) {
      this.salesSummaryReportFields.push({ name: this.getText('net_paid_to_business'), value: Formatter.formatMoney(salesSummaryReport.netPaidToBusiness) });
    }

    this.help = this.getText('total') + ' = ' + this.getText('subtotal');
    if (!ProtoUtil.isMoneyZero(salesSummaryReport.discounts)) {
      this.help += ' - ' +  this.getText('discounts');
    }
    this.help += ' + ' +  this.getText('tax');
    if (!ProtoUtil.isMoneyZero(salesSummaryReport.tips)) {
      this.help += ' + ' +  this.getText('tips');
    }
    if (!ProtoUtil.isMoneyZero(salesSummaryReport.deliveryFeesSelf)) {
      this.help += ' + ' +  this.getText('self_delivery_fees');
    }
    if (!ProtoUtil.isMoneyZero(salesSummaryReport.deliveryTipsSelf)) {
      this.help += ' + ' +  this.getText('self_delivery_tips');
    }
    if (!ProtoUtil.isMoneyZero(salesSummaryReport.deliveryFeesDoorDash)) {
      this.help += ' + ' +  this.getText('doordash_delivery_fees');
    }
    if (!ProtoUtil.isMoneyZero(salesSummaryReport.deliveryTipsDoorDash)) {
      this.help += ' + ' +  this.getText('doordash_delivery_tips');
    }
    if (!ProtoUtil.isMoneyZero(salesSummaryReport.creditCardFeeAdjustment)) {
      this.help += ' + ' +  this.getText('credit_card_fee_adjustment');
    }
    this.help += ' + ' +  this.getText('waiternow_fee');
    this.help += '\n\n';

    this.help += this.getText('net_paid_to_business') + ' = ' + this.getText('total');
    this.help += ' - ' +  this.getText('stripe_fee');
    this.help += ' - ' +  this.getText('waiternow_fee');
    if (!ProtoUtil.isMoneyZero(salesSummaryReport.deliveryFeesDoorDash)) {
      this.help += ' - ' +  this.getText('doordash_delivery_fees');
    }
    if (!ProtoUtil.isMoneyZero(salesSummaryReport.deliveryTipsDoorDash)) {
      this.help += ' - ' +  this.getText('doordash_delivery_tips');
    }
  }

  public showHelp(): void {
    this.dialogService.openMessageDialog(this.help);
  }

  private getText(key: string): string {
    if (this.localizationService.isSpanish()) {
      return Util.safeString(this.textsInSpanish.get(key));
    }
    return Util.safeString(this.textsInEnglish.get(key));
  }
}
