import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SidebarService } from '../../services/sidebar.service';
import { NavigationService } from '../../services/navigation.service';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { DeviceService } from 'src/app/general/services/device.service';
import { LocalizationService } from 'src/app/general/services/localization.service';
import { ActionComponent } from 'src/app/general/components/action/action.component';
import { DeviceSessionService } from '../../services/device-session.service';
import { SessionService } from '../../services/session.service';
import { VersionService } from '../../services/version.service';
import { DialogService } from 'src/app/general/services/dialog.service';

// ----------------------------------------------------------------------------
// Usage Example

// Template:

// <app-header></app-header>
// ----------------------------------------------------------------------------

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    ActionComponent
  ],
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css', '../../../../styles.css']
})
export class HeaderComponent {

  public isVersionStale?: boolean;

  constructor(
      public deviceService: DeviceService,
      public navigationService: NavigationService,
      public localizationService: LocalizationService,
      public deviceSessionService: DeviceSessionService,
      public sessionService: SessionService,
      private sidebarService: SidebarService,
      private versionService: VersionService,
      private dialogService: DialogService,
      private translateService: TranslateService) {
    this.versionService.getVersion(
      /* onSuccess= */ version => this.isVersionStale = version.isVersionStale,
      /* onError= */ error => {});
  }

  public toggleSidebar() {
    this.sidebarService.toggleSidebar();
  }

  public logout(): void {
    this.deviceSessionService.endSessionAndLogout()
    this.navigationService.goToSignInPage()
  }

  public storeOpenedClick(): void {
    this.translateService.get('store_opened').subscribe(text => {
      this.dialogService.openMessageDialog(text);
    });
  }
}
