<app-page>
    <app-title>{{ getLocationName() }}</app-title>
    <div align="center"><app-subtitle>{{ 'sales' | translate }}</app-subtitle></div>
    <table appFieldContainer align="center">
        <tr>
            <td>
                <mat-form-field appField>
                    <mat-label>{{ 'enter_a_date_range' | translate }}</mat-label>
                    <mat-date-range-input [rangePicker]="picker">
                      <input matStartDate placeholder="{{ 'start_date' | translate }}" [formControl]="startDateFormControl" required>
                      <input matEndDate placeholder="{{ 'end_date' | translate }}" [formControl]="endDateFormControl" required>
                    </mat-date-range-input>
                    <mat-hint>MM/DD/YYYY – MM/DD/YYYY</mat-hint>
                    <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-date-range-picker #picker></mat-date-range-picker>
                  </mat-form-field>
            </td>
        </tr>
        <tr>
            <td>
                <br>
            </td>
        </tr>
        <tr>
            <td align="center">
                <app-action
                    text="{{ 'submit' | translate }}"
                    [disabled]="startDateFormControl.invalid || endDateFormControl.invalid"
                    (onAsyncExecute)="getSalesSummaryReport($event)">
                </app-action>
            </td>
        </tr>
        <tr *ngIf="help">
            <td>
                <app-right-aligned-compact-content>
                    <app-action icon="help" (onExecute)="showHelp()"></app-action>
                </app-right-aligned-compact-content>
            </td>
        </tr>
        <tr>
            <td>
                <app-data-table
                    [data]="salesSummaryReportFields"
                    [columns]="columns">
                </app-data-table>
            </td>
        </tr>
    </table>
</app-page>