import * as proto from 'src/proto/compiled-protos';
import { ChangeDetectorRef, Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PageComponent } from '../../components/page/page.component';
import { LoadableContentComponent } from 'src/app/general/components/loadable-content/loadable-content.component';
import { DataTableComponent } from 'src/app/general/components/data-table/data-table.component';
import { TranslateModule } from '@ngx-translate/core';
import { TitleComponent } from 'src/app/general/components/title/title.component';
import { ActionComponent } from 'src/app/general/components/action/action.component';
import { LoadingController } from 'src/app/general/components/loadable-content/loading-controler';
import { CheckboxColumn, DataTableColumn, StringColumn } from 'src/app/general/components/data-table/data-table-column';
import { LocalizationService } from 'src/app/general/services/localization.service';
import { LoadingMessage, ErrorResult, SuccessResult } from 'src/app/general/util/result';
import { Util } from 'src/app/general/util/util';
import { NavigationService } from '../../services/navigation.service';
import { SessionService } from '../../services/session.service';
import { Formatter } from '../../util/formatter';
import { BackendService } from '../../services/backend.service';
import { SubtitleComponent } from 'src/app/general/components/subtitle/subtitle.component';
import { ActionObserver } from 'src/app/general/components/action/action';

@Component({
  selector: 'app-menu-availability-page',
  standalone: true,
  imports: [
    CommonModule,
    PageComponent,
    TranslateModule,
    LoadableContentComponent,
    DataTableComponent,
    TitleComponent,
    SubtitleComponent,
    ActionComponent
  ],
  templateUrl: './menu-availability-page.component.html',
  styleUrls: ['./menu-availability-page.component.css']
})
export class MenuAvailabilityPageComponent {
  public loadingController: LoadingController;

  public menuEntries: Array<proto.waiternow.common.MenuEntryAvailabilityProto>;
  public columns: Array<DataTableColumn<proto.waiternow.common.MenuEntryAvailabilityProto>>;

  constructor(
      private sessionService: SessionService,
      private localizationService: LocalizationService,
      private navigationService: NavigationService,
      private backendService: BackendService,
      private changeDetectorRef: ChangeDetectorRef) {
    this.columns = [
      new StringColumn(
        /* name= */ 'menuEntry',
        /* translateId= */ 'menu_entry',
        /* valueExtractor= */  menuEntry => "" + this.getText(menuEntry.name)),
      new CheckboxColumn(
        /* name= */ 'isAvailable',
        /* translateId= */ 'available',
        /* stateExtractor= */ menuEntry => !menuEntry.isUnavailable,
        /* onStateChanged= */  (menuEntry, isAvailable) => menuEntry.isUnavailable = !isAvailable),
    ];
    this.menuEntries = new Array();
    this.loadingController = new LoadingController();
  }

  ngOnInit(): void {
    // TODO: if the menu availability page is refreshed, it would show empty data because locations
    // have not been fetched. The home page redirects to SelectLocationPage if no location has
    // been selected, and then SelectLocationPage redirects back to the home page once the location
    // is selected.
    if (!this.sessionService.getLocation()) {
      this.navigationService.goToHomePage();
      return;
    }
    this.loadMenuEntries();
  }

  public loadMenuEntries(): void {
    this.loadingController.onLoadingStarted(LoadingMessage.empty());
    this.backendService.getStructuredMenuAvailability(
      Util.safeString(this.sessionService.getLocation()?.id),
      /* onSuccess= */ menuEntriesProto => {
        for (const menuEntry of menuEntriesProto) {
          this.menuEntries.push(new proto.waiternow.common.MenuEntryAvailabilityProto(menuEntry));
        }
        this.loadingController.onSuccess();
          // This is needed because the update may be done async after the result of a backend call, and thus after Angular change detection process.
          this.changeDetectorRef.detectChanges();
      },
      /* onError */ error => {
        this.loadingController.onError(ErrorResult.withErrorAndMessageTranslateId(error, 'error_internal'));
      }
    );
  }

  public updateMenuAvailability(actionObserver: ActionObserver): void {
    this.backendService.updateStructuredMenuAvailability(
      Util.safeString(this.sessionService.getLocation()?.id),
      this.menuEntries,
      /* onSuccess= */ () => {
        actionObserver.onSuccess(SuccessResult.withMessageTranslateId('updated'));
      },
      /* onError= */ error => {
        actionObserver.onError(ErrorResult.withErrorAndMessageTranslateId(error, 'error_internal'));
      }
    );
  }

  public getLocationName(): string {
    const location = this.sessionService.getLocation();
    if (location) {
      return location.redundantData?.businessName + ' ' + location.name;
    }
    return '';
  }

  private getText(textProto: proto.waiternow.common.ITextProto | null | undefined): string {
    let str: string | null | undefined;
    if (this.localizationService.isSpanish()) {
      str = Formatter.getText(textProto, proto.waiternow.common.Language.SPANISH);
    }
    else {
      str = Formatter.getText(textProto, proto.waiternow.common.Language.ENGLISH);
    }
    if (!str) {
      str = textProto?.text;
    }
    return Util.safeString(str);
  }
}
