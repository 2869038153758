<app-page>
    <app-loadable-content [loadingController]="loadingController" (onRetry)="loadMenuEntries()">
        <app-title>{{ getLocationName() }}</app-title>
        <div align="center"><app-subtitle>{{ 'menu' | translate }}</app-subtitle></div>
        <table width="100%">
            <tr>
                <td>
                    <app-data-table
                        [data]="menuEntries"
                        [columns]="columns">
                    </app-data-table>
                </td>
            </tr>
            <tr>
                <td align="center">
                    <app-action text="{{ 'update' | translate }}" (onAsyncExecute)="updateMenuAvailability($event)"></app-action>
                </td>
            </tr>
        </table>
    </app-loadable-content>
</app-page>