import * as proto from 'src/proto/compiled-protos';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PageComponent } from '../../components/page/page.component';
import { IndeterminatePaginatorData, IndeterminatePaginatorModel } from 'src/app/general/components/indeterminate-paginator/indeterminate-paginator-model';
import { Observable } from 'rxjs';
import { SessionService } from '../../services/session.service';
import { TranslateModule } from '@ngx-translate/core';
import { DataTableComponent } from 'src/app/general/components/data-table/data-table.component';
import { IndeterminatePaginatorComponent } from 'src/app/general/components/indeterminate-paginator/indeterminate-paginator.component';
import { LoadableContentComponent } from 'src/app/general/components/loadable-content/loadable-content.component';
import { DataTableColumn, StringColumn } from 'src/app/general/components/data-table/data-table-column';
import { LoadingController } from 'src/app/general/components/loadable-content/loading-controler';
import { Formatter } from '../../util/formatter';
import { LocalizationService } from 'src/app/general/services/localization.service';
import { LoadingMessage, ErrorResult } from 'src/app/general/util/result';
import { Util } from 'src/app/general/util/util';
import { NavigationService } from '../../services/navigation.service';
import { TitleComponent } from 'src/app/general/components/title/title.component';
import { SubtitleComponent } from 'src/app/general/components/subtitle/subtitle.component';
import { OrdersService } from '../../services/orders.service';

@Component({
  selector: 'app-order-history-page',
  standalone: true,
  imports: [
    CommonModule,
    PageComponent,
    IndeterminatePaginatorComponent,
    TranslateModule,
    LoadableContentComponent,
    DataTableComponent,
    TitleComponent,
    SubtitleComponent
  ],
  templateUrl: './order-history-page.component.html',
  styleUrls: ['./order-history-page.component.css']
})
export class OrderHistoryPageComponent implements OnInit, IndeterminatePaginatorModel<proto.waiternow.common.IOrderProto> {

  public ordersLoadingController: LoadingController;

  public orders: Array<proto.waiternow.common.IOrderProto>;
  public columns: Array<DataTableColumn<proto.waiternow.common.IOrderProto>>;

  public indeterminatePaginatorModel: IndeterminatePaginatorModel<proto.waiternow.common.IOrderProto>;
  public initialPaginatorData: IndeterminatePaginatorData<proto.waiternow.common.IOrderProto>;

  constructor(
      private sessionService: SessionService,
      private localizationService: LocalizationService,
      private navigationService: NavigationService,
      private ordersService: OrdersService,
      private changeDetectorRef: ChangeDetectorRef) {
    this.columns = [
      new StringColumn(
        /* name= */ 'orderNumber',
        /* translateId= */ 'order_number',
        /* valueExtractor= */  order => "" + order.orderNumber),
      new StringColumn(
        /* name= */ 'orderType',
        /* translateId= */ 'order_type',
        /* valueExtractor= */  order => this.getOrderType(order)),
      new StringColumn(
        /* name= */ 'customerName',
        /* translateId= */ 'customer_name',
        /* valueExtractor= */  order => this.getCustomerName(order),
        /* hideInCompactMode= */ true),
      new StringColumn(
        /* name= */ 'status',
        /* translateId= */ 'status',
        /* valueExtractor= */  order => Formatter.formatOrderStatus(order),
        /* hideInCompactMode= */ true),
      new StringColumn(
        /* name= */ 'creationTime',
        /* translateId= */ 'creation_time',
        /* valueExtractor= */  order => Formatter.formatTimestampProtoWithLocale(order.creationTime)),
    ];
    this.ordersLoadingController = new LoadingController();
    this.orders = new Array();
    this.initialPaginatorData = this.ordersService.getPaidOrders();
    this.indeterminatePaginatorModel = this;
  }

  ngOnInit(): void {
    // TODO: if the order history page is refreshed, it would show empty data because locations
    // have not been fetched. The home page redirects to SelectLocationPage if no location has
    // been selected, and then SelectLocationPage redirects back to the home page once the location
    // is selected.
    if (!this.sessionService.getLocation()) {
      this.navigationService.goToHomePage();
      return;
    }
    this.loadInitialData();
  }

  public updatePage(page: proto.waiternow.common.IOrderProto[]): void {
    this.orders = page;
    // This is needed because the update may be done async after the result of a backend call, and thus after Angular change detection process.
    this.changeDetectorRef.detectChanges();
  }

  public fetchData(continuationToken: string | null | undefined): Observable<IndeterminatePaginatorData<proto.waiternow.common.IOrderProto>> {
    return this.ordersService.fetchPaidOrders();
  }

  public loadInitialData(): void {
    if (this.initialPaginatorData.data && this.initialPaginatorData.data.length > 0) {
      return;
    }
    this.ordersLoadingController.onLoadingStarted(LoadingMessage.empty());
    this.fetchData(/* continuationToken= */ null).subscribe(
      {
        next: paginatorData => {
          this.initialPaginatorData = paginatorData;
          this.ordersLoadingController.onSuccess();
          // This is needed because the update may be done async after the result of a backend call, and thus after Angular change detection process.
          this.changeDetectorRef.detectChanges();
        },
        error: error => {
          this.ordersLoadingController.onError(ErrorResult.withErrorAndMessageTranslateId(error, 'error_loading_orders'));
        }
      }
    );
  }

  public getLocationName() {
    const location = this.sessionService.getLocation();
    if (location) {
      return location.redundantData?.businessName + ' ' + location.name;
    }
    return '';
  }

  public getCustomerName(order: proto.waiternow.common.IOrderProto | null | undefined): string {
    if (order && order.redundantData) {
      return Util.safeString(order.redundantData.consumerName);
    }
    return '';
  }

  // NOTE: this method is duplicated in OrderComponent and HomePage
  public getOrderType(order: proto.waiternow.common.IOrderProto | null | undefined): string {
    if (order && order.redundantData) {
      const redundantData = order.redundantData;
      if (redundantData.isDelivery) {
        return  this.localizationService.isSpanish() ? 'Entrega a Domicilio' : 'Delivery';
      }
      else if (redundantData.isOnlinePickup) {
        return  this.localizationService.isSpanish() ? 'Order en Linea Para Recoger' : 'Online Pickup';
      }
      else if (redundantData.isOnsitePickup) {
        return  this.localizationService.isSpanish() ? 'Order en Localidad Para Recoger' : 'Onsite Pickup';
      }
      else if (redundantData.pointOfServiceFriendlyName) {
        return redundantData.pointOfServiceFriendlyName;
      }
    }
    return '';
  }

  public onRowClick(order: proto.waiternow.common.IOrderProto) {
    this.navigationService.goToOrderPageFromOrderHistoryPage(Util.safeString(order.id));
  }
}
